.countryDetailContainer {
    max-width: 600px;
    padding-left: var(--spacing-xl);
    padding-right: var(--spacing-xl);
    font-size: 16px;
}

.countryDetailContainer img {
    margin-bottom: var(--spacing-l);
}

.countryDetailContainer h3 {
    margin-top: var(--spacing-xs);
}

@media only screen and (min-width: 920px ) {
    .countryDetail > .row {
        align-items: center;
    }
}