.MuiOutlinedInput-root {
    color: var(--text-color);
    background: var(--element-background);
}

.MuiOutlinedInput-root > div {
    color: var(--text-color);
    background: var(--element-color);
}

#searchInput {
    color: var(--text-color)
}

.MuiOutlinedInput-root fieldset {
    border:none;
    box-shadow: var(--box-shadow) 0px 0px 6px;
}

.MuiSvgIcon-root path {
    fill: var(--text-color);
}