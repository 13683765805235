.card {
    max-width: 100%;
    border-radius: var(--border-radius);
    margin-bottom: var(--spacing-l);
    background: var(--element-background);
}

@media only screen and (min-width: 440px) {

    .card {
        max-width: 100%;
        margin: 0 var(--spacing-xl) var(--spacing-xxl) var(--spacing-xl);
        border-radius: var(--border-radius);
        background: var(--element-background);
    }

    nav.container {
        padding-left: var(--spacing-xl);
        padding-right: var(--spacing-xl);
    }

    nav.container .row {
        padding-left: var(--spacing-l);
        padding-right: var(--spacing-l);
    }

}

@media only screen and (min-width: 768px) {

    nav.container .row {
        justify-content: space-between;
        padding-left: var(--spacing-xl);
        padding-right: var(--spacing-xl);
    }

    nav.container .row .col {
        padding-left: 0;
        padding-right: 0;
    }

}

.card .cardBody {
    padding: var(--spacing-sm) var(--spacing-xl) var(--spacing-xl);
}

.card img {
    width: 100%;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    aspect-ratio: 16 / 10;
    object-fit: cover;
}