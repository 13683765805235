:root {
    --dark-blue: hsl(209, 23%, 22%);
    --very-dark-blue: hsl(207, 26%, 17%);
    --dark-gray: hsl(0, 0%, 52%);
    --very-light-gray: hsl(0, 0%, 98%);
    --white: hsl(0, 0%, 100%);
    --box-shadow: rgba(0, 0, 0, 0.1);
    --box-shadow-strong: rgba(0, 0, 0, 0.3);
    --container-padding-x: 1rem;
    --container-max-width: 1280px;
    --border-radius: .5rem;
    --spacing-xs: .5rem;
    --spacing-sm: .75rem;
    --spacing-l: 1rem;
    --spacing-xl: 2rem;
    --spacing-xxl: 3.5rem;

}

.boxShadow {
    box-shadow: var(--box-shadow) 0px 0px 6px;
}

.boxShadowStrong {
    box-shadow: var(--box-shadow-strong) 0px 0px 6px;
}

.noMargin {
    margin: 0 !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body {
    font-size: 14px;
    color: var(--text-color);
    width: 100%;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 300;
}

body {
    background: var(--main-background);
}

a {
    color: var(--text-color);
    text-decoration: none;
}

.icon {
    fill: var(--text-color);
}

button {
    color: var(--text-color);
    background: var(--element-background);
    border: none;
    font-size: inherit;
    font-weight: 600;
}

main {
    width: 100%;
}

img, svg {
    max-width: 100%;
    height: auto;
    display: inline-block;
}

h1 {
    font-size: calc(1rem + 1.5vw) !important;
}

@media (min-width: 1200px) {
    h1 {
        font-size: 2.5rem !important;
    }
}

header {
    margin-bottom: var(--spacing-xxl);
    padding-top: var(--spacing-l);
    padding-bottom: var(--spacing-l);
    background: var(--element-background);
}

#switchMode {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
}

#switchMode svg {
    margin-right: var(--spacing-xs);
    width: 1.5rem;
    fill: var(--text-color);
}

nav {
    margin-bottom: var(--spacing-xxl);
    padding: 0 var(--spacing-xl);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.container {
    max-width: var(--container-max-width);
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--container-padding-x);
    padding-right: var(--container-padding-x);
}

.row {
    display: flex;
    flex-direction: column;
    margin-left: calc(var(--spacing-l)*-1);
    margin-right: calc(var(--spacing-l)*-1);
}

header .row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-left: var(--spacing-xl);
    padding-right: var(--spacing-xl);
}

.col {
    padding-left: var(--spacing-l);
    padding-right: var(--spacing-l);
    width: 100%;
    margin-bottom: var(--spacing-l);
}

.colAuto {
    width: auto !important;
}


@media only screen and (min-width: 768px) {

    .cardContainer {
        padding-left: var(--spacing-l);
        padding-right: var(--spacing-l);
        display: flex;
        flex-wrap: wrap;
    }

    .cardContainer > a {
        width: 50%;
        flex: 0 0 auto;
    }

}

@media only screen and (min-width: 920px) {
    
    .cardContainer > a {
        width: 33.33%;
    }

    .countryDetailContainer {
        max-width: var(--container-max-width);
    }

    .row {
        flex-direction: row;
    }

    .col3 {
        width: calc(3 / 12 * 100%);
    }

    .col5 {
        width: calc(5 / 12 * 100%);
    }

    .col6 {
        width: 50%;
    }

    .col7 {
        width: calc(100% * 7 / 12);
    }

    .col9 {
        width: calc(100% * 9 / 12);
    }

}

@media only screen and (min-width: 1200px) {
    
    .cardContainer > a {
        width: 25%;
    }

}


/*
TODO:

- equal height for rows



*/